import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';


declare const google: any;

@Component({
  selector: 'app-edit-area',
  templateUrl: './edit-area.component.html',
  styleUrls: ['./edit-area.component.css']
})
export class EditAreaComponent implements OnInit {

  lat = 49.178807;
  lng = 16.695596;
  loading: boolean;
  drawingManager: any;
  area: any;
  area_id: number;
  areaCoords: any;

  formGroup: FormGroup;



  // tslint:disable-next-line:variable-name
  constructor(private API: ApiService, private route: ActivatedRoute, private formBuilder: FormBuilder, private router: Router) {
  }

  ngOnInit() {
      this.area_id = this.route.snapshot.params.id;

      this.formGroup = this.formBuilder.group({
        name: ['', Validators.required],
        pin: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      });
  }

  onMapReady(map) {
    this.initDrawingManager(map);
  }

  async initDrawingManager(map: any) {

    const options = {
      drawingControl: false,
      polygonOptions: {
        draggable: false,
        editable: true
      }
    };

    const image = '/assets/images/business.png';


    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.area = await this.API.getArea(this.area_id);
    this.formGroup.patchValue(this.area.area);

    const polygonCoords = JSON.parse(this.area.area.coords);
    this.areaCoords = this.area.area.coords;

    this.lat = polygonCoords[0].lat;
    this.lng = polygonCoords[0].lng;

    const polygon = new google.maps.Polygon({
      paths: polygonCoords,
      strokeColor: '#000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#14b7ff',
      fillOpacity: 0.35,
      editable: true
    });


    google.maps.event.addListener(polygon.getPath(), 'set_at',  () => {
      this.areaCoords = JSON.stringify(polygon.getPath().getArray());
      console.log(this.areaCoords);
    });

    google.maps.event.addListener(polygon.getPath(), 'insert_at', ()  => {
      this.areaCoords = JSON.stringify(polygon.getPath().getArray());
      console.log(this.areaCoords);
    });


    polygon.setMap(map);

    this.area.buildings.forEach( (buldings) => {

      const marker = new google.maps.Marker({
        position: {lat: buldings.gps_lat, lng: buldings.gps_lon},
        title: buldings.name,
        icon: image
      });

      marker.setMap(map);
    });


    this.drawingManager.setMap(map);


  }


  async saveArea() {

    this.loading = true;
    const formData = {...this.formGroup.value, ...{coords: this.areaCoords}};
    console.log(formData);
    await this.API.editArea(this.area_id, formData);
    this.loading = false;
    // this.buildingsCount = data.buildings;

    setTimeout(() => {
      this.router.navigate(['/areas']);
    }, 2000);

  }



}
