import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { User } from '../../_models';
import { UserService, AuthenticationService } from '../../_services';
import {ApiService} from '../../_services/api.service';

@Component({ templateUrl: 'home.component.html', styleUrls: ['./home.component.css'] })
export class HomeComponent implements OnInit {
    currentUser: User;
    users = [];
    stats: any;
    loading: boolean;

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private API: ApiService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.loading = true;
    }

    ngOnInit() {
        this.init();
    }
    async init() {
        this.stats = await this.API.getStats();
        this.loading = false;
    }
}
