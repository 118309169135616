import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, PageEvent} from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ApiService} from '../../_services/api.service';
import {ConfirmDialog} from '../../shared/components/confirm-dialog/confirm-dialog.component';
import {AddBuildingDialog} from './add-building/add-building.component';
import {Router} from '@angular/router';

// tslint:disable-next-line:class-name
export interface building {
  id: number;
  building_code: string;
  name: string;
  gps_lat: string;
  gps_lon: string;
  address: string;
  keys: string;
  note: string;
}


@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.css']
})
export class BuildingsComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  buildings: any;
  areas: any;
  BuildingsCount: any;
  sorting = {};
  search: string;
  areaFilterId: number;
  loading = true;
  progressBar = true;


  displayedColumns: string[] = ['building_code', 'name', 'address', 'keys', 'action'];
  public dataSource = new MatTableDataSource<building>();
  // dataSource = new MatTableDataSource([]);

  constructor(private api: ApiService, private dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.init().then(() => {
      this.BuildingsCount = this.buildings.rowCount;
      this.paginator.length = this.BuildingsCount;
      this.dataSource.sort = this.sort;

    });
  }

  async init() {

    this.buildings = await this.api.getBuildings();
    this.areas = await this.api.getAreas();
    this.dataSource.data = this.buildings.data as building[];

    this.loading = false;
    this.progressBar = false;

  }
  async fullTextSearch(value: string) {
    this.search = value;
    if (value.length === 0) {

      this.search = '';

    } else if (value.length <= 3 ) {
      return;
    }
    this.doFilter();

  }

  async getNext(event: PageEvent) {

    this.doFilter(event.pageIndex);
  }

  openMap(lat: string, lng: string) {
    window.open('https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng, '_blank');
  }


  async areaFilter(event: any) {

    this.areaFilterId = event.value;
    this.doFilter();

  }

  async getSortedData(e) {
    this.sorting = e;
    this.doFilter();
  }


  async doFilter(offset = 0) {

    this.progressBar = true;
    this.buildings = await this.api.getBuildings(offset, {...this.sorting, ...{ search: this.search },  ...{ area: this.areaFilterId }} );
    this.dataSource.data = this.buildings.data as building[];
    this.BuildingsCount = this.buildings.rowCount;
    this.paginator.length = this.BuildingsCount;
    if (offset === 0) {
      this.paginator.pageIndex = 0;
    }
    this.progressBar = false;

  }





  async addBuilding() {
    let dialogRef: MatDialogRef<AddBuildingDialog>;

    dialogRef = this.dialog.open(AddBuildingDialog, {
      width: '16%'
    });


    dialogRef.afterClosed().subscribe(response => {

      if (response !== false) {

        this.router.navigate(['/buildings/' + response.id]);
      }

    });



  }

}
