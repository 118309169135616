import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildingsComponent } from './buildings.component';
import {
  MatExpansionModule,
  MatListModule,
  MatGridListModule,
  MatTabsModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatButtonModule,
  MatTooltipModule,
  MatDialogModule,
  MatTableModule,
  MatPaginatorModule,
  MatInputModule,
  MatFormFieldModule,
  MatProgressSpinnerModule, MatSortModule, MatProgressBarModule, MatSelectModule

} from '@angular/material';
import {ApiService} from '../../_services/api.service';
import {RouterModule, Routes} from '@angular/router';
import { ShowBuildingComponent } from './show-building/show-building.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AddBuildingDialog } from './add-building/add-building.component';
import { AddBuildingImageDialog } from './show-building/add-building-image/add-building-image.component';

const routes: Routes = [
  {
    path: '',
    component: BuildingsComponent,
  },
  {
    path: ':id',
    component: ShowBuildingComponent,
  }
];
@NgModule({
  declarations: [BuildingsComponent, ShowBuildingComponent, AddBuildingDialog, AddBuildingImageDialog],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatListModule,
    MatGridListModule,
    MatTabsModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatSortModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    MatSelectModule
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
  ],
  entryComponents: [AddBuildingDialog, AddBuildingImageDialog],
  providers: [
      ApiService
  ]
})
export class BuildingsModule { }
