import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatTableDataSource, MatSort, PageEvent, MatDialogRef, MatDialog} from '@angular/material';
import {ApiService} from '../../_services/api.service';
import {ConfirmDialog} from '../../shared/components/confirm-dialog/confirm-dialog.component';

// tslint:disable-next-line:class-name
export interface area {
  id: number;
  pin: string;
  name: string;
}


@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.css']
})
export class AreasComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  buildings: any;
  loading = true;


  displayedColumns: string[] = ['pin', 'name', 'action'];
  public dataSource = new MatTableDataSource<area>();

  constructor(private api: ApiService, private dialog: MatDialog) { }

  ngOnInit() {
    this.init().then(() => {
      this.dataSource.paginator = this.paginator;
    });
  }

  async init() {
    this.loading = true;
    this.dataSource.data = [];
    this.buildings = await this.api.getAreas();
    this.dataSource.data = this.buildings as area[];
    this.loading = false;

  }
  async deleteArea(id: number) {

    return await this.api.deleteArea(id);

  }
  public doFilter(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  openMap(long: string, lat: string) {
    window.open('http://', '_blank');
  }

  async confirmDelete(id: number, areaName: string) {
    let dialogRef: MatDialogRef<ConfirmDialog>;

    dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.componentInstance.title = 'Upozornění';
    dialogRef.componentInstance.message = 'Opravdu chcete smazat ' + areaName + ' oblast?';
    dialogRef.componentInstance.confirmText = 'Ano';

    dialogRef.afterClosed().subscribe(result => {

      if (result === true) {
       this.loading = true;
       this.deleteArea(id).then(() => {
         this.init();
       });
      }

    });

  }

}
