import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ApiService} from '../../../../_services/api.service';
@Component({
  selector: 'app-add-building-image',
  templateUrl: './add-building-image.component.html',
  styleUrls: ['./add-building-image.component.css']
})
// tslint:disable-next-line:component-class-suffix
export class AddBuildingImageDialog {

  selectedFile: any;
  errorMessage: string;
  buldingId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddBuildingImageDialog>, private API: ApiService) {
    this.buldingId = data.buildingId;
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }


  async onUpload() {

    this.errorMessage = '';

    console.log(this.selectedFile);

    if (this.selectedFile) {
        if (this.selectedFile.type === 'image/png' || this.selectedFile.type === 'image/jpg' || this.selectedFile.type === 'image/jpeg') {

          let response;
          const uploadData = new FormData();
          uploadData.append('file', this.selectedFile, this.selectedFile.name);

          response = await this.API.uploadImage(this.buldingId, uploadData);
          if (response.status) {
            this.dialogRef.close(true);
          } else {
            this.errorMessage = 'Něco se pokazilo.';
          }
        } else {

          this.errorMessage = 'Zvolte pouze obrázek.';

        }

    }
  }

}
