import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../_services/api.service';
import {Router} from '@angular/router';



declare const google: any;

@Component({
  selector: 'app-add-area',
  templateUrl: './add-area.component.html',
  styleUrls: ['./add-area.component.css']
})
export class AddAreaComponent implements OnInit {

  lat = 49.178807;
  lng = 16.695596;
  loading: boolean;
  drawingManager: any;
  areaCoords: any;
  buildingsCount: number;
  areas: any;

  colors = ['#fffd10', '#cc6376', '#60cccc', '#54cca0', '#8c75cc', '#fff5ab', '#ffb87c', '#14b7ff', '#3fff72'];

  firstFormGroup: FormGroup;

  // tslint:disable-next-line:variable-name
  constructor(private _formBuilder: FormBuilder, private API: ApiService, private router: Router) {}

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      pin: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    });
  }

  onMapReady(map) {
    this.initDrawingManager(map);
  }

  async initDrawingManager(map: any) {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon']
      },
      polygonOptions: {
        draggable: true,
        editable: true
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    };

    this.drawingManager = new google.maps.drawing.DrawingManager(options);

    //
    // google.maps.event.addListener(this.drawingManager, 'overlaycomplete', function(event) {
    //
    // });

    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (event) => {
      this.areaCoords = JSON.stringify(event.getPath().getArray());

      google.maps.event.addListener(event.getPath(), 'set_at',  () => {
        this.areaCoords = JSON.stringify(event.getPath().getArray());
      });

      google.maps.event.addListener(event.getPath(), 'insert_at', ()  => {
        this.areaCoords = JSON.stringify(event.getPath().getArray());
      });

    });


    this.areas = await this.API.getAreas();

    this.areas.forEach( (area) => {
      const polygonCoords = JSON.parse(area.coords);
      // construct the polygon
      const polygon = new google.maps.Polygon({
        paths: polygonCoords,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35
      });
      polygon.setMap(map);
    });



    this.drawingManager.setMap(map);
  }


  async savePolygon() {

    this.loading = true;
    const formData = {...this.firstFormGroup.value, ...{coords: this.areaCoords}};
    console.log(formData);
    const data = await this.API.addArea(formData);
    console.log(data);
    this.loading = false;
    this.buildingsCount = data.buildings;

    setTimeout(() => {
      this.router.navigate(['/areas']);
    }, 5000);
  }








}
