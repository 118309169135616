import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotfoundComponent} from './shared/components/notfound/notfound.component';
import {HomeComponent} from './routes/home';
import {AuthGuard} from './_helpers';
import {LoginComponent} from './routes/login';
import {RegisterComponent} from './routes/register';
import {BuildingsComponent} from './routes/buildings/buildings.component';
import {AddAreaComponent} from './routes/areas/add-area/add-area.component';

const routes: Routes = [
  {path: 'not-found', component: NotfoundComponent},
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent},
  {
    path: 'areas',
    canActivate: [AuthGuard],
    loadChildren: './routes/areas/areas.module#AreasModule'
  },
  {
    path: 'buildings',
    canActivate: [AuthGuard],
    loadChildren: './routes/buildings/buildings.module#BuildingsModule'
  },
  {path: '**', redirectTo: 'not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule {
}
