import { Component } from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../_services/api.service';

@Component({
  selector: 'app-add-building',
  templateUrl: './add-building.component.html',
  styleUrls: ['./add-building.component.css']
})

// tslint:disable-next-line:component-class-suffix
export class AddBuildingDialog {

  formGroup: FormGroup;
  errorMessage: string;

  constructor(public dialogRef: MatDialogRef<AddBuildingDialog>, private formBuilder: FormBuilder, private API: ApiService) {

    this.formGroup = this.formBuilder.group({

      name: ['', Validators.required],
      building_code: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],


    });
  }

  async onSubmit(data) {

    if (this.formGroup.valid) {
      let response: any;
      response = await this.API.addBuilding(data);

      if (response.status === 'ok') {
          this.dialogRef.close({id: response.id});

      } else {
          this.errorMessage = response.message;
      }

    }
  }
}
