import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddAreaComponent } from './add-area/add-area.component';
import {AgmCoreModule} from '@agm/core';
import { AreasComponent } from './areas.component';
import {
  MatExpansionModule,
  MatListModule,
  MatGridListModule,
  MatTabsModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatButtonModule,
  MatTooltipModule,
  MatDialogModule,
  MatTableModule,
  MatPaginatorModule,
  MatInputModule,
  MatFormFieldModule,
  MatSpinner,
  MatProgressSpinnerModule,
  MatStepperModule,

} from '@angular/material';
import {ApiService} from '../../_services/api.service';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ShowAreasComponent } from './show-areas/show-areas.component';
import { EditAreaComponent } from './edit-area/edit-area.component';



const routes: Routes = [
  {
    path: '',
    component: AreasComponent,
  },
  {
    path: 'add',
    component: AddAreaComponent,
  },
  {
    path: 'show',
    component: ShowAreasComponent,
  },
  {
    path: 'edit/:id',
    component: EditAreaComponent,
  }
];

@NgModule({
  declarations: [AddAreaComponent, AreasComponent, ShowAreasComponent, EditAreaComponent],
  imports: [
    CommonModule,
    AgmCoreModule,
    MatExpansionModule,
    MatListModule,
    MatGridListModule,
    MatTabsModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    RouterModule
  ],
  providers: [
    ApiService
  ]
})
export class AreasModule { }
