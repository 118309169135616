import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../_services/api.service';
import {Router} from '@angular/router';


declare const google: any;

@Component({
  selector: 'app-show-areas',
  templateUrl: './show-areas.component.html',
  styleUrls: ['./show-areas.component.css']
})
export class ShowAreasComponent implements OnInit {

  lat = 49.178807;
  lng = 16.695596;
  loading: boolean;
  drawingManager: any;
  areas: any;


  colors = ['#14b7ff', '#cc6376', '#60cccc', '#54cca0', '#8c75cc', '#fff5ab', '#ffb87c'];

  // tslint:disable-next-line:variable-name
  constructor(private API: ApiService) {}

  ngOnInit() {
  }

  onMapReady(map) {
    this.initDrawingManager(map);
  }

  async initDrawingManager(map: any) {

    const options = {
      drawingControl: false,
      polygonOptions: {
        draggable: false,
        editable: false
      }
    };

    this.drawingManager = new google.maps.drawing.DrawingManager(options);

    //
    // google.maps.event.addListener(this.drawingManager, 'overlaycomplete', function(event) {
    //
    // });

    // google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (event) => {
    //   this.areaCoords = JSON.stringify(event.getPath().getArray());
    //
    //   google.maps.event.addListener(event.getPath(), 'set_at',  () => {
    //     this.areaCoords = JSON.stringify(event.getPath().getArray());
    //   });
    //
    //   google.maps.event.addListener(event.getPath(), 'insert_at', ()  => {
    //     this.areaCoords = JSON.stringify(event.getPath().getArray());
    //   });
    //
    // });

    // google.maps.event.addListenerOnce(map, 'idle', (map: any) => {
    //     this.drawPolygons();
    // });

    this.areas = await this.API.getAreas();

    let i = 0;
    this.areas.forEach( (area) => {
      const polygonCoords = JSON.parse(area.coords);
      // construct the polygon
      const polygon = new google.maps.Polygon({
        paths: polygonCoords,
        strokeColor: '#000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: this.colors[i],
        fillOpacity: 0.35
      });



      const bounds = new google.maps.LatLngBounds();

      polygonCoords.forEach( (tmp) => {
        bounds.extend(tmp);
      });

      const polygonCenter = bounds.getCenter();

      const markerIcon = {
        url: 'http://1x1px.me/FFFFFF-0.png',
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(7, 7),
      };


      const marker = new google.maps.Marker({
        position: polygonCenter,
        title: area.name,
        icon: markerIcon,
        animation: google.maps.Animation.DROP,
        label: {
          text: area.name,
          color: this.colors[i],
          fontSize: '16px',
          fontWeight: 'bold'
        }
      });


      i++;
      marker.setMap(map);
      polygon.setMap(map);
    });







    this.drawingManager.setMap(map);
  }


  async drawPolygons(map: any) {



  }





}
